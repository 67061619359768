import networks from '../networks'
import BaseBrowserExtension from './BaseBrowserExtension'

export default class MetaMask extends BaseBrowserExtension {

  constructor(walletProps) {
    const props = {
      name: 'MetaMask',
    }
    if (walletProps.ethereum && walletProps.ethereum.isMetaMask) {
      props.ethereum = walletProps.ethereum
    }
    super(props)
  }

  async enable(networkId = 'main') {
    if (this.ethereum) {
      const accounts = await this.ethereum.request({ method: 'eth_requestAccounts' })
      this._currentAccount = { address: accounts[0] }
      const networkConfig = networks.filter((network) => network.id === networkId)
      if (networkConfig.length > 0) {
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: networkConfig[0].chainId }],
          })
        } catch (switchError) {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfig[0].config]
          })
        }
      }
      // console.log(this.ethereum.selectedAddress);
      this._chainId = await this.ethereum.request({ method: 'eth_chainId' })
      // console.log(this._chainId);
      // this._chainId = '0x38';

      this.ethereum.on('chainChanged', (chainId) => {
        this._chainId = chainId
        this._onNetworkChanged && this._onNetworkChanged(this.getNetwork())
      })
      this.ethereum.on('accountsChanged', (accounts) => {
        this._currentAccount = { address: accounts[0] }
        this._onAccountChanged && this._onAccountChanged({ address: accounts[0] })
      })

      this.setEnabled(true)
      return this._chainId
    }
    return this._chainId
  }

  dispose() {
    this.ethereum.removeAllListeners('chainChanged')
    this.ethereum.removeAllListeners('accountsChanged')
  }

  async getAllAccounts() {
    const result = await this.ethereum.request({ method: 'wallet_getPermissions' })
    const found = result[0].caveats.find((c) => c.type === 'filterResponse')
    this._accounts = (found ? found.value : []).map((address) => ({ address }))
    return this._accounts
  }

  onAccountChanged(callback) {
    this._onAccountChanged = callback
    // eslint-disable-next-line no-return-assign
    return () => this._onAccountChanged = undefined
  }

  async signMessage(message) {
    return this.ethereum.request({ method: 'eth_sign', params: [this.currentAccount.address, message] })
  }

  async signTypedData(typedData) {
    return this.ethereum.request({
      method: 'eth_signTypedData',
      params: [typedData, this.currentAccount.address],
      from: this.currentAccount.address
    })
  }

  async personalSign(message) {
    return this.ethereum.request({
      method: 'personal_sign',
      params: [message, this.currentAccount.address],
      from: this.currentAccount.address
    })
  }

  async sendTransaction(tx) {
    return this.ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx]
    })
  }

  onNetworkChanged(callback) {
    this._onNetworkChanged = callback
    // eslint-disable-next-line no-return-assign
    return () => this._onNetworkChanged = undefined
  }

  onDisconnect(callback) {
    this._onDisconnect = callback
    // eslint-disable-next-line no-return-assign
    return () => this._onDisconnect = undefined
  }

  watchAsset(coinParams) {
    this.ethereum.request({
      method: 'wallet_watchAsset',
      params: coinParams
    })
  }
}
