import App from '../App'

const index = r => require.ensure([], () => r(require('./../views/Home')), 'home');
const indexM = r => require.ensure([], () => r(require('./../views/HomeM')), 'homeM');

export default [{
  path: '/',
  hidden: true,
  component: App,
  children: [
    { path: '', redirect: '/index' },
    { path: '/', redirect: '/index' },
    {
      path: '/index',
      component: index,
      name: 'index',
      meta: {
        mobileWeb: 'indexM',
      }
    },
    {
      path: '/index',
      component: indexM,
      name: 'indexM',
      meta: {
        pcWeb: 'index',
      }
    },
  ],
}];
