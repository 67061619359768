<template>
  <div>
    <el-container style="background: white !important; min-height: 100vh">
      <el-main class="index-main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style lang="css">
.el-main {
  padding: 0 !important;
}

.el-row {
  margin: 0 !important;
}

.el-container {
  margin: 0;
}

.el-footer {
  padding: 0px;
}

.main-content {
  min-height: calc(100vh - 72px);
}

.index-main {
  padding: 0;
  /* margin: 0 auto; */
}

.el-tag {
  border: none !important;
  color: #5c82ff;
  font-size: 14px;
  padding: 0px 19px;
  margin-right: 8px;
  cursor: pointer;
}

.el-tag--info {
  font-size: 12px;
}

.el-card__body {
  padding: 0px;
}
</style>

<style>
html {
  background: rgba(240, 243, 247, 1) !important;
}
</style>
