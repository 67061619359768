import MetaMask from './BrowserExtension/MetaMask'
import WalletConnect from './BrowserExtension/WalletConnect'
import Client from './Client'
import networks from './networks'

export default class Dapp {

  constructor(options) {
    // eslint-disable-next-line no-underscore-dangle
    const { extension, availableNetworks = networks } = options
    this._client = null
    this._networks = availableNetworks
    if (!extension || extension === 'MetaMask') {
      this.extension = 'MetaMask'
      this._initMetaMask()
    } else if (extension === 'WalletConnect') {
      this.extension = 'WalletConnect'
      this._initWalletConnect({})
    }

    // eslint-disable-next-line no-underscore-dangle
    if (!this._browserExtension) {
      console.warn('Unable to init the dApp. No compatible browser extension is found.')
    }
  }

  _initMetaMask() {
    if (window.ethereum && window.ethereum.isMetaMask) {
      this._browserExtension = new MetaMask({
        ethereum: window.ethereum,
      })
      return this._browserExtension
    }
  }

  _initWalletConnect(providerOptions) {
    this._browserExtension = new WalletConnect({
      providerOptions
    })
    // change provider for wallet connect
    window.ethereum = this._browserExtension.connector
    return this._browserExtension
  }

  get browserExtension() {
    return this._browserExtension
  }

  get isBrowserExtensionInstalled() {
    return Boolean(this.browserExtension)
  }

  get isBrowserExtensionEnabled() {
    return this.isBrowserExtensionInstalled && (this.browserExtension.isEnabled || false)
  }

  async enableBrowserExtension(networkId = 'test') {
    let chainId = ''
    if (this.isBrowserExtensionInstalled || localStorage.getItem('walletconnect')) {
      chainId = await this.browserExtension.enable(networkId)
      this.initRpcFromChainId(chainId)
      this.browserExtension.callOnEnabled(chainId)
    }
    return chainId || ''
  }

  onEnabled(callback) {
    return this.isBrowserExtensionInstalled && this.browserExtension.onEnabled(callback)
  }

  get network() {
    return this.isBrowserExtensionInstalled && this.browserExtension.getNetwork()
  }

  onNetworkChanged(callback) {
    let that = this
    const handler = (network) => {
      that.initRpcFromChainId(network.chainId)
      callback(network)
    }
    return this.isBrowserExtensionInstalled && this.browserExtension.onNetworkChanged(handler)
  }

  initRpcFromChainId(chainId) {
    if (chainId) {
      // @ts-ignore
      const network = this._networks.find(n => n.chainId === chainId)
      if (network) {
        this._client = new Client(network)
      }
    }
  }

  getAccount(address) {
    if (this._client) {
      return this._client.getAccount(address)
    }
    return this._newClient().getAccount(address)
  }

  get rpc() {
    return this._client && this._client.provider
  }

  get explorer() {
    return this._client && this._client.explorer
  }

  get currentAccount() {
    return this.isBrowserExtensionInstalled && this.browserExtension.currentAccount
  }

  onAccountChanged(callback) {
    return this.isBrowserExtensionInstalled && this.browserExtension.onAccountChanged(callback)
  }

  onDisconnect(callback) {
    return this.isBrowserExtensionInstalled && this.browserExtension.onDisconnect(callback)
  }

  async signMessage(message) {
    return this.isBrowserExtensionInstalled && this.browserExtension.signMessage(message)
  }

  async signTypedData(typedData) {
    return this.isBrowserExtensionInstalled && this.browserExtension.signTypedData(typedData)
  }

  async personalSign(message) {
    return this.isBrowserExtensionInstalled && this.browserExtension.personalSign(message)
  }

  watchAsset(coinParams) {
    this.isBrowserExtensionInstalled && this._browserExtension.watchAsset(coinParams)
  }

  async sendTransaction({ from, to, value, ...others }) {
    return this.isBrowserExtensionInstalled && this.browserExtension.sendTransaction({
      from,
      to,
      value: value.toHexString(),
      ...others
    })
  }

  async runContractTransactionFunc(address, abi, funcName, ...args) {
    return this._client.runContractTransactionFunc(address, abi, funcName, ...args)
  }

  async queryContract(address, abi, funcName, ...args) {
    if (this._client && this._client.networkId() === window.networkId) {
      return this._client.runContractTransactionFunc(address, abi, funcName, ...args)
    }
    return this._newClient().queryContract(address, abi, funcName, ...args)
  }

  async mint(address, abi, mintMethod, mintCost, ...args) {
    let params = await this._client.populateContract(address, abi, mintMethod, ...args)
    return this.sendTransaction({
      from: this.currentAccount.address,
      value: mintCost,
      ...params
    })
  }

  getSigner() {
    return this._client.getSigner()
  }

  async waitForTransactionResult(transactionHash) {
    let ret = await this._client.provider.waitForTransaction(transactionHash)
    if (ret.status !== 1) {
      throw new Error('transaction exec failed')
    }
    return ret
  }

  utils() {
    this._ensureClient().getUtils()
  }

  formatEther(wei) {
    return this._ensureClient().formatEther(wei)
  }

  formatUnits(ether, num = 18) {
    return this._ensureClient().formatUnits(ether, num)
  }

  parseEther(ether) {
    return this._ensureClient().parseEther(ether)
  }

  parseUnits(ether, num = 18) {
    return this._ensureClient().parseUnits(ether, num)
  }

  _ensureClient() {
    return this._client ? this._client : this._newClient()
  }

  _newClient() {
    let network = this._networks.find(n => n.id === window.networkId)
    return new Client(network)
  }
}
