// TODO: cookie should be new instance
module.exports = {
  init: function (opt) {
    opt = opt || {}
    this.path = opt.path == undefined ? "/" : opt.path
    this.domain = opt.domain == undefined ? "" : opt.domain
    this.expires = opt.expires || 1000 * 60 * 60 * 24 * 365
    this.secure = opt.secure || ""
  },
  set: function (key, value) {
    var now = new Date()
    if (typeof(this.expires) == "number") {
      now.setTime(now.getTime() + this.expires)
    }
    document.cookie =
      key + "=" + escape(value)
      + ";expires=" + now.toGMTString()
      + ";path=" + "/"
      + (this.domain == "" ? "" : ("; domain=" + this.domain))
      + (this.secure ? "; secure" : "")
  },

  get: function (key) {
    var a, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)")
    if (a = document.cookie.match(reg)) {
      return unescape(a[2])
    } else {
      return ""
    }
  },

  remove: function (key) {
    var old = this.expires
    this.expires = -1 * 1000 * 60 * 60 * 24 * 365
    this.set(key, "")
    this.expires = old
  }
}
