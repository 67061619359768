let networks = [
  {
    id: 'moonbase',
    config: {
      chainId: '0x507',
      chainName: 'Moonbase Alpha',
      nativeCurrency: {
        name: 'DEV',
        symbol: 'DEV',
        decimals: 18
      },
      rpcUrl: 'https://rpc.api.moonbase.moonbeam.network',
      blockExplorerUrl: 'https://moonbase.moonscan.io',
    }
  },
  {
    id: 'moonriver',
    config: {
      chainId: '0x505',
      chainName: 'Moonriver',
      nativeCurrency: {
        name: 'MOVR',
        symbol: 'MOVR',
        decimals: 18
      },
      rpcUrl: 'https://rpc.api.moonriver.moonbeam.network',
      blockExplorerUrl: 'https://moonriver.moonscan.io',
    }
  },
  {
    id: 'bsc_test',
    config: {
      chainId: '0x61',
      chainName: 'BSC Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
      },
      rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
      blockExplorerUrl: 'https://testnet.bscscan.com',
    }
  },
  {
    id: 'bsc',
    config: {
      chainId: '0x38',
      chainName: 'Binance Smart Chain',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
      },
      rpcUrl: 'https://bsc-dataseed.binance.org',
      blockExplorerUrl: 'https://bscscan.com',
    }
  },
  {
    id: 'harmony_test',
    config: {
      chainId: '0x6357d2e0',
      chainName: 'Harmony Testnet',
      nativeCurrency: {
        name: 'ONE',
        symbol: 'ONE',
        decimals: 18
      },
      rpcUrl: 'https://api.s0.b.hmny.io',
      blockExplorerUrl: 'https://explorer.pops.one',
    }
  },
  {
    id: 'harmony',
    config: {
      chainId: '0x63564c40',
      chainName: 'Harmony',
      nativeCurrency: {
        name: 'ONE',
        symbol: 'ONE',
        decimals: 18
      },
      rpcUrl: 'https://api.harmony.one',
      blockExplorerUrl: 'https://explorer.pops.one',
    }
  },
  {
    id: 'eth_test_rinkeby',
    config: {
      chainId: '0x4',
      chainName: 'Rinkeby Test Network',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrl: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      blockExplorerUrl: 'https://rinkeby.etherscan.io',
    }
  },
  {
    id: 'eth',
    config: {
      chainId: '0x1',
      chainName: 'Ethereum',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      blockExplorerUrl: 'https://etherscan.io',
    }
  },
  {
    id: 'polygon_test',
    config: {
      chainId: '0x13881',
      chainName: 'Polygon Testnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrl: 'https://rpc-mumbai.maticvigil.com',
      blockExplorerUrl: 'https://mumbai.polygonscan.com',
    }
  },
  {
    id: 'polygon',
    config: {
      chainId: '0x89',
      chainName: 'Polygon',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrl: 'https://polygon-rpc.com',
      blockExplorerUrl: 'https://polygonscan.com',
    }
  },
]

for (let i = 0; i < networks.length; i++) {
  let networkConfig = networks[i].config
  networks[i].name = networkConfig.chainName
  networks[i].chainId = networkConfig.chainId
  networks[i].url = networkConfig.rpcUrl
  networks[i].explorer = networkConfig.blockExplorerUrl
}

export default networks
